import { useRef } from "react";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import breezelearnIcon from "../assets/mockups/breezelearn.svg"
import loubbyIcon from "../assets/mockups/loubby.svg"
import profitincIcon from "../assets/mockups/profitinc.svg"
import royaltiIcon from "../assets/mockups/royalti.svg"
import { Slide } from "react-awesome-reveal";

const items = [
  {
    id: 1,
    title: "Loubby AI",
    img: loubbyIcon,
    summary: "Delivered an AI-driven hiring automation feature on the Loubby AI platform, enhancing decision-making by 25% and scaling to over 30,000 users with AWS deployment.",
    role: "AI-First Full Stack Developer",
    location: "Round Rock, Texas",
    link: "https://www.loubby.ai/",
  },
  {
    id: 2,
    title: "Profit Inc Portal",
    img: profitincIcon,
    summary: "Designed and implemented the Profit Inc Advisory Portal, creating over 59 user interfaces and 180+ REST APIs, while reducing operational costs by 70% with serverless AWS architecture.",
    role: "Senior Software Engineer",
    location: "Round Rock, Texas",
    link: "https://www.profitinc.com/",
  },
  {
    id: 3,
    title: "Breezelearn Website",
    img: breezelearnIcon,
    summary: "Converted UI/UX designs to functional web application pages, using mockups provided by a UI/UX designer as guide.",
    role: "Senior Software Engineer",
    location: "Alberta, Texas",
    link: "https://www.breezelearn.com/",
  },
  {
    id: 4,
    title: "Royalti",
    img: royaltiIcon,
    summary: "Led the Frontend team and managed the client-side application on a contract base, refactored the old web application after which moved on to integrate more features into the Royalti platform",
    role: "Senior Frontend Developer",
    location: "Lagos, Nigeria",
    link: "https://royalti.io/",
  },
];

const Single = ({ item }) => {
  const ref = useRef();

  const { scrollYProgress } = useScroll({
    target: ref,
  });

  const y = useTransform(scrollYProgress, [0, 1], [-300, 300]);

  return (
    <section class="flex justify-center items-center w-screen h-screen scroll-snap-align-center overflow-hidden">
      <div class="max-w-screen-lg h-full m-auto flex flex-col lg:flex-row gap-12">
        <div class="flex-1 h-1/2 sm:h-full px-6 z-10 bg-black" ref={ref}>
        <Slide >
          <img src={item.img} alt="mockup" class="w-full h-full object-contain sm:object-contain" />
          </Slide>
        </div>
        <motion.div class="flex-1 flex flex-col items-start md:gap-6 p-4 sm:text-left mx-3" style={{ y }}>
          <h2 class="text-xl font-semibold lg:text-2xl text-white">{item.title}</h2>
          <p class="text-base lg:text-lg text-ash font-semibold  w-full md:w-3/5">{item.summary}</p>

          <ul class="list-disc space-y-1 mt-2 mx-3">
            <li class="text-base lg:text-lg text-ash font-semibold">{item?.role} </li>
            <li class="text-base lg:text-lg text-ash font-semibold">{item?.location} </li>
            <li class="text-base lg:text-lg text-ash font-semibold underline">
              <a href={item.link} target="_blank" rel="noopener noreferrer">{item.link}</a>
            </li>
          </ul>
         
        </motion.div>
      </div>
  </section>
  );
};

const Portfolio = () => {
  const ref = useRef();

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "start start"],
  });

  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
  });

  return (
    <div className="relative bg-black" ref={ref}>
      <div className="sticky top-0 left-0 pt-12 px-6  text-4xl lg:text-3xl z-20 bg-black">
      <h3 className='text-carton text-5xl md:text-8xl font-medium my-4 ' > FEATURED WORKS</h3>
        <motion.div style={{ scaleX }} className="h-2 bg-white"></motion.div>
      </div>
      {items.map((item) => (
        <Single item={item} key={item.id} />
      ))}
    </div>
  );
};

export default Portfolio;