import React from 'react'
import { motion } from "framer-motion";
import { Fade } from "react-awesome-reveal";

const Skills = () => {
  const sliderVariants = {
    initial: {
      x: 0,
    },
    animate: {
      x: "-220%",
      transition: {
        repeat: Infinity,
        repeatType:"mirror",
        duration: 360,
      },
    },
  };

  const languages = ["Python", "Vanilla Javascript", "Typescript"];
  
  const frontendFrameworks = [
    "React.js",
    "Next.js",
    "HTML",
    "CSS",
    "SASS/SCSS",
    "Chakra UI",
    "Tailwind CSS",
    "StyledComponents",
    "MaterialUI",
    "three.js",
    "GSAP"
  ];
  
  const backendFrameworks = [
    "Node.js",
    "Express.js",
    "Django",
    "FastApi",
    "GraphQL",
    "AWS Amplify"
  ];
  const databases = ["MySQL", "PostgreSQL", "MongoDB", "DynamoDB"]
  const cloud = ["AWS", "Microsoft Azure", "GCP", "Digital Ocean", "Heroku"];
  const others = ["Docker", "Git", "Agile", "Jest", "React Testing Library", "ReactQuery", "Redux", "React Context API"];

  return (
    // <div className="bg-gradient-to-r from-carton to-carton50 flex justify-start">
    //   <div className="max-w-full flex flex-col max-w-[1440px] h-full px-10 pb-6 items-start justify-start text-black">
    //     <div className='my-4'>
    //         <h3 className='text-black text-8xl font-medium ' > SKILLS</h3>
    //         <div className='flex gap-3'>
    //             <p className='text-pfBlack text-2xl'>Tools used by my hands </p>
    //             {/* <FaArrowDown size={"1.7rem"} /> */}
    //         </div>
    //     </div>

    //     </div>

    // </div>

    <section className="flex flex-col md:flex-row items-start justify-center w-full text-9xl bg-black py-20 relative">
      <div className='p-6'>
        <h3 className='text-carton text-5xl md:text-8xl  font-medium' > SKILLS</h3>
        <div className='flex gap-3'>
          <p className='text-carton90 text-2xl md:text-4xl font-semibold'>Tools used by my hands </p>
          {/* <FaArrowDown size={"1.7rem"} /> */}
        </div>
      </div>
           
            <div className='mx-auto w-4/5 md:w-3/5 pt-8 md:pt-20 flex flex-wrap md:gap-8 gap-4'>

              <div>
                <h3 className='text-lg text-carton font-semibold'>LANGUAGES</h3>
                <ul className='list-disc list-inside space-y-2'>
                  {
                    languages?.map((item, idx)=> (
                      <Fade delay={1e2} cascade damping={1e-1}>
                        <li key={idx} className='text-carton90 text-base font-medium'>{item}</li>
                      </Fade>
                    ))
                  }
                </ul>

              </div>
              <div>
                <h3 className='text-lg text-carton font-semibold'>DATABASES</h3>
                <ul className='list-disc list-inside space-y-2'>
                  {
                    databases?.map((item, idx)=> (
                      <Fade delay={1e2} cascade damping={1e-1}>
                        <li key={idx} className='text-carton90 text-base font-medium'>{item}</li>
                      </Fade>
                    ))
                  }
                </ul>
              </div>
            
              <div>
                <h3 className='text-lg text-carton font-semibold'>CLOUD</h3>
                <ul className='list-disc list-inside space-y-2'>
                  {
                    cloud?.map((item, idx)=> (
                      <Fade delay={1e2} cascade damping={1e-1}>
                        <li key={idx} className='text-carton90 text-base font-medium'>{item}</li>
                      </Fade>
                    ))
                  }
                </ul>

              </div>
              <div>
                <h3 className='text-lg text-carton font-semibold'>FRONTEND</h3>
                <ul className='list-disc list-inside space-y-2'>
                  {
                    frontendFrameworks?.map((item, idx)=> (
                      <Fade delay={1e2} cascade damping={1e-1}>
                      <li key={idx} className='text-carton90 text-base font-medium'>{item}</li>
                      </Fade>
                    ))
                  }
                </ul>
              </div>
              <div>
                <h3 className='text-lg text-carton font-semibold'>BACKEND</h3>
                <ul className='list-disc list-inside space-y-2'>
                  {
                    backendFrameworks?.map((item, idx)=> (
                      <Fade delay={1e2} cascade damping={1e-1}>
                        <li key={idx} className='text-carton90 text-base font-medium'>{item}</li>
                      </Fade>
                    ))
                  }
                </ul>

              </div>
       
              <div>
                <h3 className='text-lg text-carton font-semibold'>OTHERS</h3>
                <ul className='list-disc list-inside space-y-2'>
                  {
                    others?.map((item, idx)=> (
                      <Fade delay={1e2} cascade damping={1e-1}>
                      <li key={idx} className='text-carton90 text-base font-medium'>{item}</li>
                      </Fade>
                    ))
                  }
                </ul>

              </div>
              <div>
              <Fade delay={1e2} cascade damping={1e-1}>
                <p className='text-carton text-lg whitespace-nowraps'>I upskill myself everyday in order to be familiar with newer technologies so this list could get really long 😅.</p>
               </Fade>
              </div>
       
          
            </div>

            <motion.div
              className='absolute text-[50vh] -bottom-8 whitespace-nowrap text-[#FFFFFF15]'
              variants={sliderVariants}
              initial="initial"
              animate="animate"
            >
              SKILLS
            </motion.div>
        </section>
  )
}

export default Skills