import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

import { FaArrowDown } from "react-icons/fa6";

const Contact = () => {
    const form = useRef();
    const [showNotif, setShowNotif] = useState(false)
    const [loading, setLoading] = useState(false)

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true)
        emailjs
          .sendForm('service_2hqu9a8', 'template_g1lbalp', form.current, {
            publicKey: '1h7UwnDruNX0WabfQ',
          })
          .then(
            () => {
                setShowNotif(true)
                setLoading(false)
                form.current.reset();
              console.log('SUCCESS!');
            },
            (error) => {
                setLoading(false)
              console.log('FAILED...', error.text);
            },
          );
      };
    
  return (
    <div className="bg-gradient-to-r from-carton to-carton50 flex justify-start">
        <div className="max-w-full flex flex-col max-w-[1440px] h-full px-6 md:px-10 pb-4 items-start justify-start text-black">
            <div className='my-6 md:my-10'>
            {
                showNotif &&
                <div className='bg-black flex justify-between items-center px-4'>
                    <p className='text-primary'>{'Message Sent'}</p>
                    <div className=" text-5xl cursor-pointer text-primary" onClick={()=>setShowNotif(false)}>
                        ×
                    </div>
                </div>
            }
            <div className='my-4'>
                <h3 className='text-black text-5xl md:text-8xl font-medium ' > CONTACT ME</h3>
                <div className='flex gap-3'>
                    <p className='text-pfBlack text-2xl'>Would love to hear from you </p>
                    <FaArrowDown size={"1.7rem"} />
                </div>
                {/* <p className='md:w-3/5 w-full text-pfBlack'>
                    I am currently interested in a “Full-time Front-end developer role (remote) or Full Stack developer role(remote)", 
                    but still open to other opportunities. However, if you have other requests or questions, don’t hesitate to use the form.
                </p> */}
            </div>
      
            </div>
      
            {/* bg-black bg-opacity-10 p-6 rounded-md */}
            <form className="w-full" ref={form} onSubmit={sendEmail}>
                <div className='flex flex-col justify-center items-center gap-8 md:flex-row'>
                    <div className="w-[348px]">
                        <input
                            type="text"
                            id="name"
                            placeholder='Name'
                            name="name"
                            className="w-11/12 px-3 py-4 mb-4 border bg-black bg-opacity-10 border-[#FFFFFF66] rounded-xl focus:outline-none placeholder-black"
                            required
                        />
                    </div>
                    <div className="w-[348px]">
                        <input
                            type="email"
                            id="email"
                            placeholder='Email'
                            name="email"
                            className="w-11/12 px-3 py-4 mb-4 border bg-black bg-opacity-10 border-[#FFFFFF66] rounded-xl focus:outline-none placeholder-black"
                            required
                        />
                    </div>
                </div>
                <div className="w-full">
                    <textarea
                        id="message"
                        name="message"
                        rows="10"
                        placeholder="Message"
                        className="w-11/12 px-3 py-4  border mb-4 bg-black bg-opacity-10 border-[#FFFFFF66] rounded-xl focus:outline-none placeholder-black"
                        required
                    />
                </div>

                <div className='w-full flex'>
                    <button
                        type="submit"
                        className="bg-black text-white py-4 px-6 rounded-full hover:bg-blue-600 w-full md:w-[178px] ml-auto"
                    >
                    {loading ? 'Sending...' : 'Send Message'}
                </button>
                </div>
            </form>

        </div>
    </div>
  )
}

export default Contact