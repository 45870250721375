
import "./scss/main.css";
import "./index.css"
import Home from './components/Home';
import Footer from "./components/Footer";
import { useEffect, useRef } from "react";
import Portfolio from "./components/Portfolio";
import Clients from "./components/Clients";
import Contact from "./components/Contact";
import Skills from "./components/Skills";

import AOS from 'aos';
import 'aos/dist/aos.css';


function App() {

  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView() 

  useEffect(() => {
    AOS?.init();
  }, [])

  return (
    <div>
      <Home executeScroll={executeScroll}  />
      <Clients />
      <Portfolio />
      <Skills />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
