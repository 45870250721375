// src/components/ArrowDownIcon.js

import React from 'react';

const ArrowDownIcon = () => {
  return (
    <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6 text-black"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
            d="M16 17l-4 4m0 0l-4-4m4 4V3"
          />
        </svg>
  );
};

export default ArrowDownIcon;
