import React from 'react'
import Profile from '../assets/home/profile.jpg'
import ArrowDownIcon from './icons/ArrowDownIcon'
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useGSAP } from '@gsap/react';
import { motion } from "framer-motion";
import { Fade, Slide } from "react-awesome-reveal";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, useGSAP);

const Home = ({executeScroll}) => {
  // const homeRef = useRef(null);
  // useEffect(() => {
  //   const homeElement = homeRef.current;
  //   const sections = homeElement.querySelectorAll('section');
  //   sections.forEach((panel, index) => {
  //     ScrollTrigger.create({
  //       trigger: panel,
  //       start: index === 0 ? 'top top' : 'top top+=1', // Ensure the first section starts at the top
  //       pin: true,
  //       pinSpacing: false,
  //     });
  //   });
   
  //   // ScrollTrigger.create({
  //   //   snap: 1 / sections.length,
  //   // });
  // }, []);

  // const textVariants = {
  //   initial: {
  //     x: -500,
  //     opacity: 0,
  //   },
  //   animate: {
  //     x: 0,
  //     opacity: 1,
  //     transition: {
  //       duration: 1,
  //       staggerChildren: 0.1,
  //     },
  //   },
  //   scrollButton: {
  //     opacity: 0,
  //     y: 10,
  //     transition: {
  //       duration: 2,
  //       repeat: Infinity,
  //     },
  //   },
  // };

  const sliderVariants = {
    initial: {
      x: 0,
    },
    animate: {
      x: "-220%",
      transition: {
        repeat: Infinity,
        repeatType:"mirror",
        duration: 360,
      },
    },
  };
  
  return (
    <div>
      <section className="home max-w-[1440px] max-h-screen sticky top-0">
        <div 
          className='home-content'
          // data-aos='fade-right'
          // data-aos-easing='linear'
          // data-aos-duration='800'
        >
          <Slide triggerOnce>
            <h1> SOFTWARE </h1>
            <h1> ENGINEER </h1>
            <h3> - VISIONARY PROBLEM SOLVER </h3>
          </Slide>

         

          <div className='absolute left-2 md:bottom-20 bottom-5 m-2 flex flex-col justify-center items-center'>
            <div className="animate-bounce-custom">
            <ArrowDownIcon />
            </div>
            <p className='sm:hidden'>SCROLL DOWN</p> 
            <p className='md:hidden text-sm'>SCROLL</p> 
          </div>

          <div className='rounded-xl md:hidden mb-4'>
              <img src={Profile} alt="icon" className='rounded-xl h-[400px] w-full' />
          </div>

        </div>
        <div className='home-right'>
          <div 
            className='home-image-div rounded-xl sm:hidden'
            data-aos='fade-left'
            data-aos-easing='linear'
            data-aos-duration='800'
          >
            <img src={Profile} alt="icon" className='rounded-xl' />
          </div>
          <div 
            className=' absolute md:bottom-20 bottom-10 right-10 text-right'
          >

          <Slide 
            triggerOnce
            direction='right'
          >
         
            <h3> - RESULT DRIVEN ARCHITECT </h3>
            <h1> PAUL </h1>
            <h1> ADAROJE </h1>
            </Slide>
          </div>
        </div>

        <motion.div
          className='absolute text-[50vh] -bottom-8 whitespace-nowrap text-[#FFFFFF30] sm:hidden'
          variants={sliderVariants}
          initial="initial"
          animate="animate"
        >
          BUILD DEVELOP SCALE
        </motion.div>
          {/* <h2 className='absolute text-[50vh] -bottom-[150px] whitespace-nowrap text-[#ffffff09]'>I BUILD AMAZING PRODUCTS</h2> */}
      </section>
        {/* <div ref={homeRef} className=""> */}
       
        <section className="flex sm:flex-col items-start justify-center w-full text-9xl bg-black sticky top-0 md:py-20 py-6 rounded-t-3xl">
        
          <h3 className='text-carton md:text-8xl text-5xl font-medium px-6' > ABOUT ME</h3>
          <div className='mx-auto w-full md:w-1/2 md:pt-20 pt-6'>
          
          <p 
            className=" text-ash text-start md:text-4xl text-3xl leading-11 font-semibold px-6"
          >
            <Fade delay={1e2} cascade damping={1e-1}>
              <p>  I've spent years creating <span className="text-carton">exceptional user experiences</span>.</p>
           
              
            {/* and adapt flawlessly to any device.  My backend expertise ensures everything runs smoothly under the hood.  I also leverage <span className="text-carton">automation </span>  
            where it makes sense, streamlining the development process for maximum <span className="text-carton">efficiency</span>.  
            In short, I build applications that are a joy to use, from the first click to global scale. */}

          <p className='pt-4'>

            From crafting <span className="text-carton">sleek interfaces</span> that captivate users to building <span className="text-carton">robust</span> backends that power them.
          </p>
          <p className='pt-4'>
          I thrive on the challenge of <span className="text-carton">ambitious projects</span> and love collaborating with <span className="text-carton">talented teams</span> to create something truly special. 
          </p>
          {/* I'm passionate about building high-performance, accessible applications that leave a lasting impression on users. */}
          <p className='pt-4'>
            With a deep understanding of both <span className="text-carton">Frontend</span> and <span className="text-carton">Backend</span> development, coupled with my <span className="text-carton">Cloud</span> expertise, I craft seamless solutions that are efficient, accessible, and <span className="text-carton">scalable</span>.
          </p>
          </Fade>
          </p>
      
          </div>
          <motion.div
            className='absolute text-[50vh] -bottom-8 whitespace-nowrap text-[#FFFFFF15]'
            variants={sliderVariants}
            initial="initial"
            animate="animate"
          >
            ABOUT ME
          </motion.div>
        </section>
        {/* </div> */}
     
      </div>
  )
}

export default Home

