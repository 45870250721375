import React from 'react'
import LinkedIn from '../assets/icons/linkedin-white.svg'
import Twitter from '../assets/icons/twitter-white.svg'
import Email from '../assets/icons/email-white.svg'

const Footer = () => {
  return (
    <div className='bg-gradient-to-r from-carton to-carton50 px-8'>

    <hr />
    <div className='flex flex-col md:flex-row justify-between items-center py-4'>
      <div className='flex gap-4 sm:my-3'>
          <a href="https://www.linkedin.com/in/pauladaroje/" target="_blank" rel="noopener noreferrer" ><img src={LinkedIn} alt="icon" /></a>
          <a href="https://www.twitter.com/paulkeno_/" target="_blank" rel="noopener noreferrer" ><img src={Twitter} alt="icon" /></a>
          <a href="mailto:pauladaroje@gmail.com" target="_blank" rel="noopener noreferrer" ><img src={Email} alt="icon" /></a>
      </div>
      <p className='text-black sm:text-center sm:text-sm'>Copyright © {new Date().getFullYear()} by Paul Adaroje, All rights reserved.</p>
    </div>
      

    </div>
  )
}

export default Footer