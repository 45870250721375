
import { motion } from "framer-motion";

//Utilities
import creditchekLogo from "../assets/clients/creditchek.svg"
import profitInc from "../assets/clients/profitInc.svg"
import tapvigo from "../assets/clients/tapvigo.svg"
import proforma from "../assets/clients/proforma.svg"
import royalti from "../assets/clients/royalti.svg"
import loubby from "../assets/clients/loubby.svg"
import bountip from "../assets/clients/bountip.svg"
import breezelearn from "../assets/clients/breezelearn.svg"
import carestation from "../assets/clients/carestation.svg"
import cre from "../assets/clients/cre.svg"
import phamtom from "../assets/clients/phamtom.svg"
import divverse from "../assets/clients/divverse.png"

const Clients = () => {

  const style = "md:w-56 md:h-14"

  return (
    <section className="relative bg-gradient-to-r from-carton via-white to-carton50 py-8 text-center mx-auto">
        {/* <motion.div style={{ x }} className="flex gap-4">
          {cards.map((card) => {
            return <Card card={card} key={card.id} />;
          })}
        </motion.div> */}
        <div className="flex flex-col overflow-hidden [mask-image:linear-gradient(to_right,transparent,black,transparent)]">
          <h3 className="text-black pb-6 text-2xl font-semibold sm:hidden">Clients I've Worked With</h3>
          <h3 className="text-black pb-6 text-2xl font-semibold md:hidden">Clients</h3>
          <motion.div
            className="flex gap-16 flex-none pr-16"
            animate={{
              translateX: "-231%"
            }}
            transition={{
              duration: 15,
              repeat: Infinity,
              ease: "linear",
              repeatType: "loop"
            }}
          >
            <img src={profitInc} alt="" className={style} />
            <img src={tapvigo} alt="" className={style} />
            <img src={proforma} alt="" className={style} />
            <img src={royalti} alt="" className={style}  />
            <img src={loubby} alt="" className={style}  />
            <img src={creditchekLogo} alt="" className={style} />
            <img src={bountip} alt="" className={style} />
            <img src={breezelearn} alt="" className={style} />
            <img src={carestation} alt="" className={style} />
            <img src={cre} alt="" className={style} />
            <img src={phamtom} alt="" className={style} />
            <img src={divverse} alt="" className={style} />

            <img src={profitInc} alt="" className={style} />
            <img src={tapvigo} alt="" className={style} />
            <img src={proforma} alt="" className={style} />
            <img src={royalti} alt="" className={style}  />
            <img src={loubby} alt="" className={style}  />
            <img src={creditchekLogo} alt="" className={style} />
            <img src={bountip} alt="" className={style} />
            <img src={breezelearn} alt="" className={style} />
            <img src={carestation} alt="" className={style} />
            <img src={cre} alt="" className={style} />
            <img src={phamtom} alt="" className={style} />
            <img src={divverse} alt="" className={style} />
        
          </motion.div>
        </div>

    </section>
  );
};


export default Clients;